import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

const url2="https://restdev.ciptatapaksejahtera.co.id"
const url1='https://rest.ciptatapaksejahtera.co.id'
const url='https://rest.ciptatapaksejahtera.co.id'

window.$name = 'king'
window.$ImageBaseUrl = url
window.$webAdd=url+'/WebMessage/add'
window.$customerIndex=url+'/OurCustomer/Index'
window.$eventIndex=url+'/OurEvent/Index'
window.$eventFindById=url+'/OurEvent/findById'
window.$storyIndex=url+'/OurStory/Index'
window.$storyFindById=url+'/OurStory/findById'
window.$productIndexImage=url+'/Product/indexImage'
window.$productIndex=url+'/Product/index'
window.$productFind=url+'/Product/find'
window.$productListBrandCat=url+'/Product/ListBrandCat'
window.$productFindById=url+'/Product/FindById'
window.$brandIndex=url+'/Brand/Index'
window.$brandFindById=url+'/Brand/findById'
window.$brandFindByName=url+'/Brand/findByName'
window.$picmapIndex=url+'/PicMap/Index'
window.$picmapDelete=url+'/PicMap/Delete'
window.$picmapFindById=url+'/PicMap/findById'
window.$picmapFindByName=url+'/PicMap/findByName'
window.$contentIndexCompact=url+'/Contents/indexcompact'
window.$contentIndex=url+'/Contents/index'

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
