import React, { Component } from 'react'
import styled from 'styled-components';
import './footer2.css'

export default class Footer extends Component {
	state = {
		content:{},
      }
      componentDidMount(){
		window.addEventListener('scroll', this.listenScrollEvent)
		this.loadContent()
	  }
	  loadContent(){
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
	
		var raw = "";
	
		var requestOptions = {
		  method: 'POST',
		  headers: myHeaders,
		  body: raw,
		  redirect: 'follow'
		};
	
		fetch(window.$contentIndexCompact, requestOptions)
		  .then(response => response.json())
		  .then(result => {
			this.setState({
			  content:result.result
			})
		  })
		  .catch(error => console.log('error', error));
      }
      findByName(names){
		if(names!=null){
		  return names.value
		}
		 return ""
		
	   }
	   findByUrl(url){
		if(url!=null){
		  return window.$ImageBaseUrl+"/"+url.img_url
		}
		 return require('../assets/placeholder.jpg')
		
	   }
    render() {
        const {content}=this.state;
        return(
		  <footer className="footer" style={{backgroundColor:'#17181c'}}>
		  <div className="row">
		 <div className="col sm-4">
		 </div>
		 <div className="col sm-4">
		 <div className="footer-copyright text-center py-4 justify-content-center mt-2" style={{color:'white',textAlign:'center',verticalAlign:'middle'}}>
          © {this.findByName(content.COMPANY_NAME)}
          </div>
		 </div>
		 <div className="col sm-4 justify-content-end d-flex" >
		 <div className="row">
		 <div className="col"></div>
		 <div className="col">
		 <div className="row">
		 <div className="col sm-4  d-flex"> 
		 <div className="mr-1 mt-1 jsutify-content">
		 <text style={{fontSize:14}}>Member of:</text>
		 </div>
		 </div>
		 <div className="w-100"></div>
		 <div className="col col sm-4 justify-content-end d-flex">
		 <div className="mr-3 mt-2 mb-2">
		 <a href="https://interciptacorp.com/" target="_blank"><img src={require('../assets/logo_intercipta.png')} style={{width:180,height:50}}></img></a>
		 </div>
		 </div>
		 </div>
		 </div>
		 </div>

		 </div> 
		  </div>
          
            </footer>

        );
    }
}