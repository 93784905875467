import React from "react"
import ContentLoader from "react-content-loader" 

class MyLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }
  render(){
    return(
  <div >
  <ContentLoader 
  speed={2}
  width={220}
  height={270}
  viewBox="0 0 200 270"
  backgroundColor="#9d9d9d"
  foregroundColor="#ecebeb"
>
  <rect x="552" y="336" rx="3" ry="3" width="88" height="6" /> 
  <rect x="603" y="343" rx="3" ry="3" width="52" height="6" /> 
  <rect x="472" y="338" rx="3" ry="3" width="410" height="6" /> 
  <rect x="497" y="332" rx="3" ry="3" width="380" height="6" /> 
  <rect x="457" y="339" rx="3" ry="3" width="178" height="6" /> 
  <circle cx="601" cy="333" r="20" /> 
  <rect x="0" y="0" rx="0" ry="0" width="250" height="80" /> 
  <rect x="588" y="320" rx="0" ry="0" width="60" height="28" /> 
  <rect x="0" y="97" rx="0" ry="0" width="250" height="13" /> 
  <rect x="0" y="117" rx="0" ry="0" width="250" height="13" /> 
  <rect x="0" y="138" rx="0" ry="0" width="250" height="13" /> 
  <rect x="0" y="158" rx="0" ry="0" width="250" height="13" />
</ContentLoader>
  </div>
  )
    }
}
export default MyLoader