import React from "react";
import { Link } from 'react-router-dom';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Bars from './BarLoading';
import './dialog.css';

class MyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      hovered:false
    };
  }
  handlerCopy(e) {
    console.log(e.target.innerHTML);
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();

    this.setState(prevState => ({
      counter: prevState.counter + 1
    }));

    alert('Don\'t copy it!');
  }
  render(){
    return(
    <React.Fragment>
    <Dialog open={this.props.show} fullWidth maxWidth="sm">
    {this.props.done ? ( 
        <div>
        <DialogTitle>{this.props.name}</DialogTitle>
        <Divider/>
        <DialogContent>
            <div className="container">
              <Zoom zoomMargin={80}>
              <img className="img-fluid " src={this.props.image} alt={this.props.name} onError={(e)=>e.target.src=window.$ImageBaseUrl+"/"+this.props.images[0].url} onMouseOut={()=>this.setState({hovered:false})} onMouseOver={()=>this.setState({hovered:true})} style={{maxHeight:300,backgroundColor:'white',maxWidth:400}}/>
              </Zoom>
              <div style={{textAlign:'left'}}>
              <p > 
                <text style={{color:'grey',textAlign:'left'}}>Brand : </text>
                <text style={{color:'black'}}>{this.props.brand}</text>
              </p>
              <p> 
                <text style={{color:'grey'}}>Category : </text>
                <text style={{color:'black'}}>{this.props.category}</text>
              </p>
              <p>
                <text style={{color:'grey',alignContent:'left'}}>Code : </text>
                <text style={{color:'black',alignContent:'left'}}>{this.props.code}</text>
              </p>
              <p>
                <text style={{color:'grey'}}>Description : </text>
                <div onContextMenu={(e)=> e.preventDefault()} className="noselect"   style={{height:200,overflowY:'scroll'}}>
                  <InputBase className="noselect" unselectable="yes" onselectstart={(e)=>{return false;}} onpaste="return false;" onCopy={(e)=>{return false;}} onCut="return false" onDrag="return false;" onDrop="return false" multiline fullWidth disabled style={{color:"#000"}} value={this.props.desc} />
                </div>
              </p>
              </div>
            </div>
        </DialogContent>
        </div>
      ) : (
       <DialogContent> <Bars/> </DialogContent>
      )}
      
      <Divider/>
      <DialogActions>
      <Button onClick={this.props.close}>Close</Button>
      </DialogActions>
              
            
    </Dialog>
    </React.Fragment>
  )
    }
}
export default MyModal