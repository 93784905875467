export default [
	{
        "id" : "1",
        "name" : "PT PLN (Persero) Unit Induk Wilayah Aceh",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "2",
        "name" : "PT PLN (Persero) Unit Induk Wilayah Sumatera Utara",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "3",
        "name" : "PT PLN (Persero) Unit Induk Wilayah Sumatera Barat",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "4",
        "name" : "PT PLN (Persero) Unit Induk Wilayah Sumatera Selatan, Jambi, dan Bengkulu",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "5",
        "name" : "PT PLN (Persero) Unit Induk Wilayah Bangka Belitung",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "6",
        "name" : "PT PLN (Persero) Unit Induk Wilayah Riau dan Kepulauan Riau",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "7",
        "name" : "PT PLN (Persero) Unit Induk Distribusi Lampung",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "8",
        "name" : "PT PLN (Persero) Unit Induk Distribusi Banten",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "9",
        "name" : "PT PLN (Persero) Unit Induk Distribusi Jakarta Raya",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "10",
        "name" : "PT PLN (Persero) Unit Induk Distribusi Jawa Barat",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "11",
        "name" : "PT PLN (Persero) Unit Induk Distribusi Jawa Tengah",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "12",
        "name" : "PT PLN (Persero) Unit Induk Distribusi Jawa Timur",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "13",
        "name" : "PT PLN (Persero) Unit Induk Wilayah Kalimantan Barat",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "14",
        "name" : "PT PLN (Persero) Unit Induk Wilayah Kalimantan Timur dan Kalimantan Utara",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "15",
        "name" : "PT PLN (Persero) Unit Induk Wilayah Kalimantan Selatan dan Kalimantan Tengah",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "16",
        "name" : "PT PLN (Persero) Unit Induk Wilayah Sulawesi Utara, Tengah, dan Gorontalo",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "17",
        "name" : "PT PLN (Persero) Unit Induk Wilayah Sulawesi Selatan, Tenggara, dan Barat",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "18",
        "name" : "PT PLN (Persero) Unit Induk Wilayah Maluku dan Maluku Utara",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "19",
        "name" : "PT PLN (Persero) Unit Induk Distribusi Bali",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "20",
        "name" : "PT PLN (Persero) Unit Induk Wilayah Nusa Tenggara Barat",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "21",
        "name" : "PT PLN (Persero) Unit Induk Wilayah Nusa Tenggara Timur",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "22",
        "name" : "PT PLN (Persero) Unit Induk Wilayah Papua dan Papua Barat",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "23",
        "name" : "Indonesia Power UPJP Tanjung Priok",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "24",
        "name" : "Indonesia Power UJP PLTU Aadipala",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "25",
        "name" : "Indonesia Power UJP Jabar II Pelabuhan Ratu",
        image: require('../assets/company-logo2.png')
	},{
        "id" : "26",
        "name" : "PT Chevron Pacific Indonesia",
        image: require('../assets/company-logo2.png')
	}
]