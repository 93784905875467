import React from "react";
import Button from '@material-ui/core/Button';

import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ReactPlayer from 'react-player'


class MyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      showImg:false
    };
  }
  
  render(){
    const{showImg}=this.state;
    return(
    <React.Fragment>
    <Dialog open={this.props.show} onClose={this.props.close} fullWidth maxWidth="md" scroll="paper">
        <div>
        <DialogContent>
        <div className="d-flex justify-content-center">
        <ReactPlayer url={this.props.video} playing />
        </div>
        </DialogContent>
        </div>
      
      <Divider/>
      <DialogActions>
        <Button onClick={this.props.close}>Close</Button>
      </DialogActions>
              
            
    </Dialog>
    </React.Fragment>
  )
    }
}
export default MyModal