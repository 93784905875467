import React, { Component } from 'react';
import { BrowserRouter, withRouter, Switch, Redirect,Route,Link } from 'react-router-dom';

import Home from './containers/HomeContainer';
import Product from './containers/ProductContainer';
import Brand from './containers/BrandContainer';
import About from './containers/AboutContainer';
import Contact from './containers/ContactContainer';
import Profile from './containers/ProfileContainer';
import Career from './containers/EventContainer';
import Story from './containers/StoryContainer';
import Customer from './containers/CustomerContainer';
import MainNav from './components/MainNav';
import Uplaod from './containers/UploadFileContainer';
import './App.css';
import styled from 'styled-components';


class App extends Component {
  state = {
    page: "home"
  }

  ganti = (page)=> {
    this.setState({
      page: page,
      appSetting:{}
    })
  }
  componentDidMount(){
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };

    fetch("./json_dummy/application.json", requestOptions)
    .then(response => response.json())
    .then(responseJson => {
        this.setState({
          NavMenu:responseJson
        });
    })

    // fetch('./json_dummy/application.json')
    //   .then((res) => res.json())
    //   .then((data) => {
    //     console.log('data:', data);
    //   })
  }

  PageLoad = () => {

    //var MyComponent = React.createElement("Home", {});
    var Tag = `HomeContainer`;
    //const Mypage = mpage;
    return <Tag />
  }
  render() {
    return (
        <BrowserRouter >
        <Switch>
          <Route path="/product" component={Product} />
          <Route path="/product/:id" component={Product} />
          <Route path="/product/:id/:code" component={Product} />
          <Route exact path="/home" component={Home} />          
          <Route path="/story" component={Story} />
          <Route path="/contact" component={Contact} />
          <Route path="/customer" component={Customer} />
          <Route path="/event" component={Career} />
          <Route exact path="/" render={() => (<Redirect to="/home" />)} />         
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;