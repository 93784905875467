import React, { Component } from 'react';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';
import ReactLoading from 'react-loading';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


class ProfileContainer extends Component {
	render() {
		return(
            
            <div class="d-flex justify-content-center">
            <div>hashdjahsjdhajsdhkj</div>
            <Backdrop open={true}>
    <CircularProgress color="inherit" />
  </Backdrop>
            </div>
		)
	}
}

export default ProfileContainer;