import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Validator from 'validator';
import Footer from '../components/Footer2';
import MainNav  from '../components/MainNav';
import './Contact.css'


class ContactContainer extends Component {
	state = {
		name:'',email:'',subject:'',message:'',isLoading:false,content:{},
		color: 'rgba(52, 52, 52, 0.8)'
	  }
	  listenScrollEvent = e => {
		if (window.scrollY > 400) {
		  this.setState({color: 'rgba(52, 52, 52, 0.8)'})
		} else {
		  this.setState({color: 'rgba(52, 52, 52, 0.8)'})
		}
	  }
	setName=(e)=>{
		this.setState({
			name:e.target.value
		})
	}
	setEmail=(e)=>{
	  this.setState({
		  email:e.target.value
	  })
	}
	setSubject=(e)=>{
	  this.setState({
		  subject:e.target.value
	  })
	}
	setMessage=(e)=>{
	  this.setState({
		  message:e.target.value
	  })
	}
	
	
	  componentDidMount(){
		window.addEventListener('scroll', this.listenScrollEvent)
		this.loadContent()
	  }
	  loadContent(){
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
	
		var raw = "";
	
		var requestOptions = {
		  method: 'POST',
		  headers: myHeaders,
		  body: raw,
		  redirect: 'follow'
		};
	
		fetch(window.$contentIndexCompact, requestOptions)
		  .then(response => response.json())
		  .then(result => {
			this.setState({
			  content:result.result
			})
		  })
		  .catch(error => console.log('error', error));
	  }
	handleSubmit(){
		if(this.state.name!=''){
			if(Validator.isEmail(this.state.email)){
				if(this.state.subject!=''){
					if(this.state.message!=''){
						this.setState({
							isLoading:true
						})
						this.addMessage()
					}else{
						alert("Input message first")
					}
				}else{
					alert("Input subject first")
				}
			}else{
				alert("email not valid")
			}
		}else{
			alert("Input name first")
		}
	}

	addMessage(){
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	var raw = JSON.stringify({"t":"token","name":this.state.name,"email":this.state.email,"subject":this.state.subject,"message":this.state.message});

	var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
	};

	fetch(window.$webAdd, requestOptions)
	.then(response => response.json())
	.then(result => {
		if(result.status==true){
			this.setState({
				name:'',email:'',subject:'',message:'',
				isLoading:false
			})
		}else{
			this.setState({
				isLoading:false
			})
			alert("Submit message failed, please try again")
		}
	})
	.catch(error => console.log('error', error));
	}
	findByName(names){
		if(names!=null){
		  return names.value
		}
		 return ""
		
	   }
	   findByUrl(url){
		if(url!=null){
		  return window.$ImageBaseUrl+"/"+url.img_url
		}
		 return require('../assets/placeholder.jpg')
		
	   }
	render() {
		const {name,email,subject,message,isLoading,content}=this.state;
		return(
			<div style={{flex:1}} className="section-bg ">
			<Helmet>
			<title>PT Cipta tapak sejahtera - Contact</title>
			<meta name="description" content="Contact PT Cipta Tapak Sejahtera" />
			</Helmet>
			<Backdrop open={isLoading}>
        	<CircularProgress color="inherit" />
        	</Backdrop>
			<MainNav route='contact'/>


			<section id="contact" className="section-bg ">
			<div className="container pb-5 mt-5">
	  
			  <div className="section-header wow fadeInUp">
				<h3>{this.findByName(content.CONTACT_US_TITLE)}</h3>
			  </div>
	  
			  <div className="row contact-info wow fadeInUp">
	  
				<div className="col-md-6">
				<div style={{textAlign:'left'}}>
				<h5 style={{color:'black', fontWeight:'bold'}}>Leave Us A Message</h5>
				</div>
				  <div style={{textAlign:'left',marginTop:20}}>
				  <div style={{flexDirection:'column'}}>
				  <label style={{color:'black',marginTop:18,width:70}}>Name</label>
				 	<TextField value={this.state.name} type="text" id="outlined-bare1"  variant="outlined" style={{backgroundColor:'white',width:400}} onChange={e => this.setName(e)}/> 
				  </div>
				  <div style={{flexDirection:'column',marginTop:10}}>
				  <label style={{color:'black',marginTop:18,width:70}}>Email</label>
				 	<TextField value={email} type="email" id="outlined-bare2"  variant="outlined" style={{backgroundColor:'white',width:400}} onChange={e => this.setEmail(e)}/> 
				  </div>
				  <div style={{flexDirection:'column',marginTop:10}}>
				  <label style={{color:'black',marginTop:18,width:70}}>Subject</label>
				 	<TextField value={subject} type="email" id="outlined-bare3"  variant="outlined" style={{backgroundColor:'white',width:400}} onChange={e => this.setSubject(e)}/> 
				  </div>
				  <div style={{flexDirection:'column',marginTop:10}}>
				  <label style={{color:'black',marginTop:18,width:70}}>Message</label>
				  <TextField value={message} id="outlined-multiline-flexible" multiline rowsMax="5" rows="5" variant="outlined" style={{backgroundColor:'white',width:400}} onChange={e => this.setMessage(e)}/> 
				  </div>
				  <Button class="btn btn-secondary" style={{marginLeft:70,marginTop:10}} onClick={()=>this.handleSubmit()}>Submit</Button>
				  </div>
				</div>
	  
				<div className="col-md-6" style={{width:300}}>
				<div style={{textAlign:'left'}}>
				<h5 style={{color:'black', fontWeight:'bold'}}>Contact Information</h5>
				</div>
				  <div className="mt-2">
				  <Paper elevation={3}style={{height:200,width:300}}>
				  <a href="https://www.google.com/maps/place/PT+Cipta+Tapak+Sejahtera/@-6.1498948,106.7132158,17z/data=!3m1!4b1!4m5!3m4!1s0x2e69f81b301039f5:0x1bf429c28c30976f!8m2!3d-6.1498948!4d106.7154099" target="_blank"><img src={require('../assets/map.png')} style={{height:200,width:300}}/></a>
				  </Paper> 
				  </div>
				  <div style={{marginTop:10,textAlign:'left'}}>
					<h6 style={{color:'blue',marginTop:10,fontWeight:'bold'}}>{this.findByName(content.COMPANY_NAME)}</h6>
					<p><InputBase multiline fullWidth disabled style={{color:"#000"}} value={this.findByName(content.COMPANY_ADDRESS)} /></p>
					<div style={{flexDirection:'column'}}>
					<text style={{fontWeight:'bold',color:'black'}}>Phone</text>
					<text style={{color:'black',marginLeft:5}}>{this.findByName(content.COMPANY_PHONE)}</text>
					</div>
					<div style={{flexDirection:'column'}}>
					<text style={{fontWeight:'bold',color:'black'}}>Fax</text>
					<text style={{color:'black',marginLeft:5}}>{this.findByName(content.COMPANY_FAX)}</text>
					</div>
					<div style={{flexDirection:'column'}}>
					<text style={{fontWeight:'bold',color:'black'}}>Email</text>
					<text style={{color:'black',marginLeft:5}}>{this.findByName(content.COMPANY_EMAIL)}</text>
					</div>
					<div style={{flexDirection:'column'}}></div>
				  </div>
				</div>
			  </div>
			</div>
		  </section>
		  <Footer/>
		  </div>
		)
	}
}

export default ContactContainer;
