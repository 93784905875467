import React, { Component } from 'react';
import PicMap  from "../components/PicMap"
import InputBase from '@material-ui/core/InputBase';
import Carousel from 'react-bootstrap/Carousel'
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Home.css';
const logo = require('../assets/backgroud1.jpg');
class HomeContainer extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      image_height:null,
      data:{},
      items:[],
      items1:{},
      items2:{},
      items3:{},
      items4:{},
      items5:{},
      pinss:[],img_url:''
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  listenScrollEvent = e => {
    if (window.scrollY > 400) {
      this.setState({color: 'rgba(52, 52, 52, 0.8)'})
    } else {
      this.setState({color: ''})
    }
  }


  componentDidMount(){
    this.setImageSize()
    this.laodData()
    this.laodDataItem()
    this.loadPins('MY_BRAND_MAP')
    AOS.init({
      duration : 1500
    })
    window.addEventListener('scroll', this.listenScrollEvent)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({"t":"token"});

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch(window.$productIndexImage, requestOptions)
    .then(response => response.json())
    .then(result => {
      //console.log(result.result[0])
      this.setState({
        items1:result.result[0],
        items2:result.result[1],
        items3:result.result[2],
        items4:result.result[3],
        items5:result.result[4],
      })

    })
    .catch(error => console.log('error', error));


  }

  loadPins(name){
    var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        var raw = JSON.stringify({"t":"token","name":name});
    
        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
    
    fetch(window.$picmapFindByName, requestOptions)
        .then(response => response.json())
        .then(result => {
            const newData=result.result[0].items.map(a=>({...a}))
            this.setState({
                img_url:result.result[0].img_url,
                pinss:newData
            })
            
        })
        .catch(error => console.log('error', error));
  }
  laodData(){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = "";

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(window.$contentIndexCompact, requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({
          data:result.result
        })
      })
      .catch(error => console.log('error', error));
  }
  laodDataItem(){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = "";

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(window.$contentIndex, requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({
          items:result.result
        })
      })
      .catch(error => console.log('error', error));
  }
  setImageSize = () => {
    if(window.innerWidth < 600) {
        this.setState({ image_height: 10})
      } else {
        this.setState({image_height: 50})
      }  
    }
  findByName(names){
    if(names!=null){
      return names.value
    }
     return ""
    
   }
   findByUrl(url){

    if(url!=null){
      var urls=url.img_url.split('/')
      var img=window.$ImageBaseUrl+"/"+urls[0]+"/"+urls[1]+"/"+urls[2]+"/"+"L"+"/"+urls[3]
      return img
    }
     return require('../assets/placeholder.jpg')
    
   }
   loadImageItem(url){

    if(url!=null){
      var urls=url.split('/')
      var img=window.$ImageBaseUrl+"/"+urls[0]+"/"+urls[1]+"/"+urls[2]+"/"+"M"+"/"+urls[3]
      return img
    }
     return require('../assets/placeholder.jpg')
    
   }
	render() {
    const { items,data } = this.state;

		return(
      <div style={{marginTop:50}}>
      <div className="introContainer jumbo3">
      <div className="container ">
      <div className="row">
      <div className="col-md-4">
      <div className="mt-4 mb-4 p-2 wow fadeInUp" style={{backgroundColor:'rgba(200,200,200,.5)',borderRadius:"10px"}}>
      <Carousel  controls={true} style={{height:250}}>
        {items.filter(filters=> filters.name.includes('SLIDE')).map((items,idx)=>{
          if(items.img_url!==""){
            return(
              <Carousel.Item key={idx}>
              <img
                className="d-block w-100"
                src={this.loadImageItem(items.img_url)}
                style={{maxHeight:250,objectFit:'cover'}}
                alt="img" onError={(e)=>{e.target.src=window.$ImageBaseUrl+"/"+items.img_url}}
              />
            </Carousel.Item>
            )
          }
          
        })}
        
      </Carousel>
      </div>
      </div>
      <div className="col-md-8 justify-content-center">
      <div className="mt-4 wow fadeInUp">
      <h3 style={{fontWeight:700,fontSize:32}}>WELCOME</h3>
      <h3 style={{fontWeight:700}}>to</h3>
      <img style={{width:200}} src={this.findByUrl(data.COMPANY_LOGO)} alt="img" onError={(e)=>{e.target.src=window.$ImageBaseUrl+"/"+data.COMPANY_LOGO.img_url}} />
      <h3 style={{fontWeight:700,fontSize:32}}>{this.findByName(data.COMPANY_NAME)}</h3>
      </div>
      </div>
      </div>
      </div>
      </div>
      <section id="about">
      <div className="container">

        <header className="section-header wow fadeInUp">
          <h3>{this.findByName(data.ABOUT_US_TITLE)}</h3>
          <p>{this.findByName(data.ABOUT_US_CONTENT_P1)}</p>
          <p>{this.findByName(data.ABOUT_US_CONTENT_P2)}</p>
        </header>

        <div className="row about-cols">
        <div className="col-md-4 wow fadeInUp" >
        <div className="about-col" style={{height:370}}>
          <div className="img">
            <img src={this.findByUrl(data.OUR_VISION_TITLE)} alt="img" onError={(e)=>{e.target.src=window.$ImageBaseUrl+"/"+data.OUR_VISION_TITLE.img_url}} className="img-fluid"/>
            <div className="icon"><i className="ion-ios-eye-outline"></i></div>
          </div>
          <h2 className="title">{this.findByName(data.OUR_VISION_TITLE)}</h2>

          <InputBase multiline fullWidth disabled style={{color:'black',padding:10}} value={this.findByName(data.OUR_VISION_CONTENT)}/>

        </div>
      </div>

          <div className="col-md-4 wow fadeInUp" data-wow-delay="0.1s">
            <div className="about-col" style={{height:370}}>
              <div className="img">
                <img src={this.findByUrl(data.OUR_MISSION_TITLE)} alt="img" onError={(e)=>{e.target.src=window.$ImageBaseUrl+"/"+data.OUR_MISSION_TITLE.img_url}} className="img-fluid"/>
                <div className="icon"><i className="ion-ios-speedometer-outline"></i></div>
              </div>
              <h2 className="title">{this.findByName(data.OUR_MISSION_TITLE)}</h2>

              <InputBase multiline fullWidth disabled style={{color:'black',padding:10}} value={this.findByName(data.OUR_MISSION_CONTENT)}/>

            </div>
          </div>

          <div className="col-md-4 wow fadeInUp" data-wow-delay="0.2s">
            <div className="about-col" style={{height:370}}>
              <div className="img">
                <img src={this.findByUrl(data.OUR_STRATEGY_TITLE)} alt="img" onError={(e)=>{e.target.src=window.$ImageBaseUrl+"/"+data.OUR_STRATEGY_TITLE.img_url}} className="img-fluid"/>
                <div className="icon"><i className="ion-ios-list-outline"></i></div>
              </div>
              <h2 className="title">{this.findByName(data.OUR_STRATEGY_TITLE)}</h2>

              <InputBase multiline fullWidth disabled style={{color:'black',padding:10}} value={this.findByName(data.OUR_STRATEGY_CONTENT)}/>

            </div>
          </div>



        </div>
        
      </div>
      
    </section>
    <div className="section-header wow fadeInUp">
            <h3>{this.findByName(data.SALES_ON_MAP_TITLE)}</h3>
            </div>
    <div className="justify-content-center d-flex mb-4" >
    <div className="wow fadeInUp" style={{overflowX:'scroll'}}>
    <PicMap imgmain={window.$ImageBaseUrl+"/"+this.state.img_url} pins={this.state.pinss}/>
    </div>
    </div>
  <footer className="page-footer " style={{backgroundColor:'#17181c'}}>
  <div className="row">
		 <div className="col sm-4">
		 </div>
		 <div className="col sm-4">
		 <div className="footer-copyright text-center py-4 jsutify-content-center mt-2" style={{color:'white'}}>
          © {this.findByName(data.COMPANY_NAME)}
          </div>
		 </div>
     <div className="col sm-4 justify-content-end d-flex" >
		 <div className="row">
		 <div className="col"></div>
		 <div className="col">
		 <div className="row">
		 <div className="col sm-4  d-flex"> 
		 <div className="mr-1 mt-1 jsutify-content">
		 <text style={{fontSize:14,color:'#fff'}}>Member of:</text>
		 </div>
		 </div>
		 <div className="w-100"></div>
		 <div className="col col sm-4 justify-content-end d-flex">
		 <div className="mr-3 mt-2 mb-2">
		 <a href="https://interciptacorp.com/" target="_blank"><img src={require('../assets/logo_intercipta.png')} style={{width:180,height:50}}></img></a>
		 </div>
		 </div>
		 </div>
		 </div>
		 </div>
		 </div> 
		  </div>
  </footer>
	</div>
		)
	}
}

export default HomeContainer;