import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import './Home.css';
import MainNav from '../components/MainNav'
import HomeLayout1 from '../components/HomeLayout1'
import HomeLayout2 from './HomeLayout2'

class HomeContainer extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      image_height:null,
      data:{},
      items1:{},
      items2:{},
      items3:{},
      items4:{},
      items5:{},
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  state = {
    color: ''
  }
  listenScrollEvent = e => {
    if (window.scrollY > 400) {
      this.setState({color: 'rgba(52, 52, 52, 0.8)'})
    } else {
      this.setState({color: ''})
    }
  }


  componentDidMount(){
    this.setImageSize()
    this.laodData()
    window.addEventListener('scroll', this.listenScrollEvent)
  }
  setImageSize = () => {
    if(window.innerWidth < 600) {
        this.setState({ image_height: 10})
      } else {
        this.setState({image_height: 50})
      }  
    }
  laodData(){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = "";

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(window.$contentIndexCompact, requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({
          data:result.result
        })
      })
      .catch(error => console.log('error', error));
  }
  findByName(names){
    if(names!==null){
      return names.value
    }
     return ""
     }
     findByLayout(names){
      if(names!=null){
        if(names.value==='LAYOUT1'){
          return <HomeLayout1/>
        }else if (names.value==='LAYOUT2') {
          return <HomeLayout2/>
        }else{
          return <HomeLayout1/>
        }
        
      }
       return <div></div>
      
   }
   findByUrl(url){
    if(url!=null){
      return window.$ImageBaseUrl+"/"+url.img_url
    }
     return require('../assets/placeholder.jpg')
    
   }
	render() {
    const { data } = this.state;
		return(
      <div>
      <MainNav route='home'/>
      <Helmet>
			<title>PT Cipta Tapak Sejahtera</title>
			<meta name="description" content="PT CIPTA TAPAK SEJAHTERA (CTS) was established in 2012 as a representative company of world-class electrical products manufacturers in Indonesia. " />
			</Helmet>
        {this.findByLayout(data.LAYOUT)}
	</div>
		)
	}
}

export default HomeContainer;