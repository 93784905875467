import React, { Component } from 'react';
//import Axios from 'axios';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	//NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	//UncontrolledDropdown,
	//DropdownToggle,
	//DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  //Button, 
} from 'reactstrap';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import NativeSelect from '@material-ui/core/NativeSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactLoading from 'react-loading';
//import Product from '../components/Product';
//import styled from 'styled-components';
import Footer from '../components/Footer2';
import MainNav from '../components/MainNav';
import Loader from '../components/ProductLoader';
import Bars from '../components/BarLoading';
import ModalComp from '../components/ModalProduct';

import './product.css'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 200,
  },
  formControl2: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class ProductContainer extends Component {
  state = {
    requiredItem: 0,
    isLoading: true,
    isLoadingData: false,
    isDone: false,
    modalItem: [],
    showData:false,
    products: [],
    brands:[],
    catItem:[],
    catItemAll:[],
    items:{},
    searchItem:'',
    searchField:'',
    search:null,
    category:'CATU',
    brandFilter:null,
    catFilter:null,
    show: false,
    color: 'rgba(52, 52, 52, 0.8)',
    labelWidth: 0,
  };
  handleChange = event => {
    this.setState({
      setAge:event.target.value
    });
  };
  handleClose = () => {
    this.setState({
      setOpen:false
    });
  };
  handleOpen = () => {
    this.setState({
      setOpen:true
    });
  }

  openModal = product =>(product) => {
    this.setState({
      show: true,
      nameItem:product
    });
  }

  openModalWithItem(index) {
		this.setState({
      show: true,
			requiredItem: index
		});
	  }
  setFirstName = (e) => {
     this.setState({
      searchField: e.target.value.trim().toLowerCase()
     })
  }
  closeModal=nr=>()=> {
    this.setState({
        show : false
    });
  }
  handleClick(event){
    this.setState({
      products:[],
      isLoadingData:true
    });
    this.loadItems();

    }
    handleClickBrand(event){
      this.setState({
        products:[],
        isLoading:true
      });
      this.loadItem(event);
  
      }
  handleChange = e => {
      this.setState({ searchItem: e.target.value.trim().toLowerCase() });
  }
  handleBrand(id){
    var datas=this.state.brands
    console.log(id)
    this.setState({
      brandFilter:id,
      showData:true     
    })
    this.handleClickBrand(id);
    for(var i=0;i<datas.length;i++){
      var ids=datas[i].id;
      //console.log("ada")
      if(ids==id){
        
        this.setState({
          catItem:datas[i].cats
        })
        
        break
      }
    }
    
  }
  something=(event)=> {
    if (event.keyCode === 13) {
        this.searchSpace(event)
    }
  }
  changedRole = ev => {
    //console.log(ev.target.value)
    var datas=this.state.brands
    this.setState({ 
      brandFilter: ev.target.value,
     })
    if(ev.target.value==0){
      this.setState({
        catItem:this.state.catItemAll
      })
      return;
    }
    for(var i=0;i<datas.length;i++){
      var id=datas[i].id;
      //console.log("ada")
      if(id==ev.target.value){
        
        this.setState({
          catItem:datas[i].cats
        })
        break
      }
    }
    
}
changedCat = ev => {
  this.setState({ catFilter: ev.target.value })
}
  searchSpace=(event)=>{
    let keyword = event.target.value;
    this.setState({search:keyword})
  }
  listenScrollEvent = e => {
		if (window.scrollY > 400) {
		  this.setState({color: 'rgba(52, 52, 52, 0.8)'})
		} else {
		  this.setState({color: 'rgba(52, 52, 52, 0.8)'})
		}
	  }
componentDidMount(){
  ////console.log(this.props)
  this.setState({category:'catu'})
  window.addEventListener('scroll', this.listenScrollEvent)


  var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({"t":"token"});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(window.$productIndex, requestOptions)
  .then(response => response.json())
  .then(result => {
    //console.log(result.result)
  })
  .catch(error => console.log('error', error));
  this.loadcat();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({"t":"token"});
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(window.$brandIndex, requestOptions)
      .then(response => response.json())
      .then(responseJson => {
        //console.log(responseJson.result)
      this.setState({
        brands:responseJson.result,
        isLoading:false
      });
      })
      .catch(error => console.log('error', error));
}
loadItem(id){
  const{products}=this.state;
  
  let keyword=parseInt(id)
  let keyword2=this.state.searchField
  let keyword3=this.state.catFilter
  console.log(keyword)
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({"t":"token","bid":keyword,"bcat":keyword3,"q":keyword2});
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  fetch(window.$productFind, requestOptions)
  .then(response => response.json())
  .then(responseJson => {
    //console.log(responseJson.result)
    
  this.setState({
    products:responseJson.result,
    isLoading:false
  });
  })
    .catch(error => console.log('error', error));
}
loadItems(){
  const{products}=this.state;
  
  let keyword=parseInt(this.state.brandFilter)
  let keyword2=this.state.searchField
  let keyword3=this.state.catFilter
  console.log(keyword)
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({"t":"token","bid":keyword,"bcat":keyword3,"q":keyword2});
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  fetch(window.$productFind, requestOptions)
  .then(response => response.json())
  .then(responseJson => {
    //console.log(responseJson.result)
    
  this.setState({
    products:responseJson.result,
    isLoadingData:false
  });
  })
    .catch(error => console.log('error', error));
}

loadcat(){
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({"t":"token"});
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  fetch(window.$productListBrandCat, requestOptions)
    .then(response => response.json())
    .then(result => {
      //console.log(result.result)
      var cats={};
      for(var i=0;i<result.result.lenght;i++){
        cats[result.result[i]]=result.result[i]
      }
      this.setState({
       catItemAll:result.result,
       catItem:result.result
      }) 
    })
    .catch(error => console.log('error', error));
}

handleModal(id){
  
  this.setState({
    show: true,
    isDone:false
   
  });
  this.loadModal(id);
}
loadModal(id){
  var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({"t":"token","id":id});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(window.$productFindById, requestOptions)
  .then(response => response.json())
  .then(result => {
    //console.log(result)
    this.setState({
      modalItem:result.result,
      isDone:true
     
    })
    })
  .catch(error => console.log('error', error));
}
loadImage(images){
  if(images[0]!=null){
    return window.$ImageBaseUrl+"/"+images[0].url;
  }
  return require("../assets/placeholder.jpg");
}
	render() {
    
    const { showData,isLoading,isLoadingData, catItem, products,searchItem,brandFilter,catFilter,brands,modalItem,isDone } = this.state;
    const { classes } = this.props;
    let product=products;
    
    const requiredItem = this.state.requiredItem;
    const elementStyle ={
      border:'solid',
      borderRadius:'10px',
      position:'relative',
      left:'10vh',
      height:'3vh',
      width:'20vh',
      marginTop:'5vh',
      marginBottom:'10vh'
    }
		return(
<div>
<Backdrop open={isLoading}>
<CircularProgress color="inherit" />
</Backdrop>
<div>
{
  // <Navbar scrolling dark expand="md" fixed="top" style={{backgroundColor:this.state.color}}>
  //     <NavbarToggler onClick={this.toggle} color='#fff'/>
  //     <Collapse isOpen={this.state.isOpen} navbar>
  //       <Nav className="mr-auto" navbar style={{paddingRight:20}} pills defaultActiveKey="#" as="ul">
  //         <NavItem className="navItem" as="li" >
  //           <NavLink onClick={()=>{this.props.pindahHalaman('home')}} href="#Home">Home</NavLink>
  //         </NavItem>
  //         <NavItem className="navItem" as="li" active>
  //           <NavLink style={{color:'orange',fontWeight:'bold'}} onClick={()=>{this.props.pindahHalaman('product')}} href="#Product">Product</NavLink>
  //         </NavItem>
  //         <NavItem className="navItem" as="li">
  //         <NavLink onClick={()=>{this.props.pindahHalaman('career')}} href="#Career">Event</NavLink>
  //       </NavItem>
  //         <NavItem className="navItem" as="li" >
  //           <NavLink onClick={()=>{this.props.pindahHalaman('contact')}} href="#Contact">Contact Us</NavLink>
  //         </NavItem>
  //       </Nav>
  //     </Collapse>
  //   </Navbar>
 }
 
 <MainNav halaman={this.props.halaman} pindahHalaman={this.props.pindahHalaman} />

      <div className="container mb-3 " style={{marginTop:100}}>
      <div className="">
        <div className="">
          <div>
            <div className="section-header wow fadeInUp">
              <h3>Our Products</h3>
            </div>
            {isLoading ? (
              <div></div>
            ) : (<div>
            {showData?(<div>
            <div className="row section-header wow fadeInUp form-group" style={{right:0}}>
          
              <FormControl className="mb-3 ml-3 pr-2 form-group" style={{minWidth:120}}>
                  <TextField
                      id="outlined-helperText"
                      label="Search"
                      defaultValue=""
                      helperText="Type For Search in name or description"
                      variant="outlined"
                      onChange = {e => this.setFirstName(e)}
                    />
              </FormControl>
              <FormControl className="mb-3 ml-3 pr-2 form-group" style={{minWidth:120}}>
                    <Button variant="outlined" size="large"  onClick={(event) => this.handleClick(event)}>Search</Button>
              </FormControl>

            </div>

            <div className="container ">
            {isLoadingData ? (
              <div></div>
            ) : ( <div className="product-grid row">
            {product.map((info)=>(
            <div className="col-sm-3">
            <Paper elevation={3} >
              <div className="card wow fadeInUp mb-4" style={{height:270,backgroundColor:'white'}} >
                <div className="mt-2">
                    {this.loadImage(info.images) ? (<img class="img-fluid "src={this.loadImage(info.images)} style={{height:80}}/>) : (<div><img class="img-fluid "src={"../assets/placeholder.jpg"} style={{height:80}}/></div>) }
                </div>
                <hr/>
                <div className="mt-2 ml-1 mr-1" style={{textAlign:'left',flexGrow:1}}>
                    <p style={{color:'grey'}}>{info.brand.name}</p>
                      <a href="#" class="text-primary" onClick={() => this.handleModal(info.id)}>
                        {info.name}
                      </a>
                      <br />
                      
                </div>
                <div className="d-flex align-items-baseline mb-2 ml-2">
                      <Chip
                        variant="outlined"
                        size="small"
                        avatar={<Avatar>p</Avatar>}
                        label={info.code}
                        color="primary"
                        onClick={() => this.loadModal(info.id)}
                      />
                      </div>
              </div>

            </Paper>
            </div>
          ))}
          </div>)
            }
                
            </div>
            </div>):(
              <div className="d-flex justify-content-center">
              <div className="row ">
              {brands.map((item)=>(
                <div className="col-md-6 mb-3">
                
            <div className=" d-flex justify-content-center" style={{flexDirection:'row'}}>
            <Paper elevation={3} >
            <div className="card wow fadeInUp pl-3 pr-3 pt-3" style={{ width:300,height:300,backgroundColor:'white'}} >
            <div className="mt-2">
						<a href="#" onClick={()=>this.handleBrand(item.id)}><img style={{height:150, maxWidth:300}} src={this.loadImage(item.images)}/></a>
            </div>
            <hr/>
            <div className="mt-2 ml-1 mr-1" style={{textAlign:'left',flexGrow:1}}>
                        <p style={{color:'grey'}}>{item.description}</p>
                          <br />
                          
            </div>
            </div>
            </Paper>
						</div>
						
                </div>
              ))}
              </div>
              </div>
            )}
            </div>
            )}
          </div>
        </div>
      </div>
    </div>
    <Footer/>

    { modalItem.map((info)=>(
      <ModalComp
      show={this.state.show}
      close={this.closeModal()}
      name={info.name}
      image={this.loadImage(info.images)}
      brand={info.brand.name}
      category={info.category}
      code={info.code}
      desc={info.description}
      done={isDone}
      />
    ))}
  
  
</div>
</div>
		)
	}
}

ProductContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductContainer) ;