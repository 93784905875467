import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
  import styled from 'styled-components';
  const Styles = styled.div`
.navbar{
    background-color:#222;
}
.navbar-brand, .navbar-nav .nav-link{
    color : #bbb;

    &:hover{
        color:white;
    }
    &:active{
      color:white;
    }
}
.image{
  height:10;
  width:10;
}
`;

const logo = require('../assets/company-logo.png');


export default class MainNav extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      data:{},

    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  laodData(){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = "";

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(window.$contentIndexCompact, requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({
          data:result.result
        })
      })
      .catch(error => console.log('error', error));
  }
  componentDidMount(){
    this.laodData();
  }
  findByTheme(names){
    if(names!=null){
      if(names.value==='LAYOUT1'){
        return "navbar bg-dark navbar-dark transparent-bg"
      }else if (names.value==='LAYOUT2') {
        return "navbar bg-light navbar-light"
      }
      
    }
      return "navbar navbar-dark"  
  }
  setNavId(names){
    if(names!=null){
      if(names.value==='LAYOUT1'){
        return "myNavbar"
      }else if (names.value==='LAYOUT2') {
        return "myNavbar2"
      }
      
    }
      return "myNavbar"  
  }
  findByColor(names){
    if(names!=null){
      if(names.value==='layout1'){
        return '#fff'
      }else if (names.value==='layout2') {
        return '#000'
      }
      
    }
      return '#555'   
  }
  checkCurrentPage(){
  	//style={{color:'orange',fontWeight:'bold'}} 
  }
  
  checkActive(page){
  	if(page == this.props.route){
  		return "navItem active"	;
  	}
  	return "navItem";
  	
  }
  render() {
    const { location } = this.props;
    const { data } =this.state
    const page = this.props.page
    let selected = '';
    if(typeof this.props.route != '') {
      selected = this.props.route.split('/').pop();
    }
    return (
        <Navbar id={this.setNavId(data.LAYOUT)} expand="md" fixed="top" expand="md" className={this.findByTheme(data.LAYOUT)}>
          <NavbarToggler onClick={this.toggle} color={this.findByColor(data.LAYOUT)}/>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="mr-auto" navbar style={{paddingRight:20}} pills as="ul">
              <NavItem className={this.checkActive('home')} as="li" >
                <NavLink href="/home">Home</NavLink>
              </NavItem>
              <NavItem  className={this.checkActive('product')} as="li" >
             	<NavLink href="/product">Product</NavLink>
               </NavItem>
               <NavItem  className={this.checkActive('customer')} as="li" >
             	<NavLink href="/customer">Our Customer</NavLink>
           		</NavItem>
           	<NavItem   className={this.checkActive('event')} as="li">
           		<NavLink href="/event">Event</NavLink>
           </NavItem>
           <NavItem className={this.checkActive('story')}  as="li">
           		<NavLink href="/story">Stories</NavLink>
           </NavItem>
              <NavItem  className={this.checkActive('contact')} as="li" >
                <NavLink href="/contact">Contact Us</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
    );
  }
}