import React, { Component } from 'react';
import Axios from 'axios';
import Footer from '../components/Footer2';
import styled from 'styled-components';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,Modal,ModalHeader,ModalFooter,ModalBody,Button, } from 'reactstrap';
import './Brand.css'

const BrandLogo1 = require('../assets/catu-logo.png');
const BrandLogo2 = require('../assets/donghae-logo.png');
const Styles = styled.div`
.card{
    background-color:#fff;
    box-shadow:0 2px 3px rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.1);
    color:#4a4a4a;max-width:100%;
    position:relative;
}
.columns{
  margin-left:-.75;
  margin-right:-.75;
  margin-top:-.75;
}
.column{
    display:block;
    flex-basis:0;
    flex-grow:1;
    flex-shrink:1;
    padding:.75rem;
}
.image{
    padding-top:75%;
}
.is-two-thirds{
  flex:none;
  width:66.6666%;
}
.title{
  word-break:break-word;
}
`;

class BrandContainer extends Component {
	constructor(props) {
		super(props);
	

		this.state = {
		requiredItem: 0,
		isLoading: false,
		cartItems: [],
		products: [],
		search:null,
		show: false,
      nameItem:'',
      color: 'rgba(52, 52, 52, 0.8)'
		}
	  }

  openModal = product =>(product) => {
    this.setState({
      show: true,
      nameItem:product
    });
  }

  openModalWithItem(index) {
		this.setState({
      show: true,
			requiredItem: index
		});
	  }

  closeModal=nr=>()=> {
    this.setState({
        show : false
    });
  }
  something=(event)=> {
    if (event.keyCode === 13) {
        this.searchSpace(event)
    }
  }
  searchSpace=(event)=>{
    let keyword = event.target.value;
    this.setState({search:keyword})
  }

  listenScrollEvent = e => {
		if (window.scrollY > 400) {
		  this.setState({color: 'rgba(52, 52, 52, 0.8)'})
		} else {
		  this.setState({color: 'rgba(52, 52, 52, 0.8)'})
		}
    }
    
componentDidMount(){

  window.addEventListener('scroll', this.listenScrollEvent)

  fetch('https://product-list.glitch.me/')
    .then(res => res.json())
    .then(products => this.setState({ products }));

	let headers = new Headers();

  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');

  headers.append('Access-Control-Allow-Origin', 'http://localhost:3000');
  headers.append('Access-Control-Allow-Credentials', 'true');

  headers.append('GET', 'POST', 'OPTIONS');

    var data = new FormData;
    data.append("t", "fMqyXjyjrOSd3YRa1krLKNw8yZnTDxW4");
    data.append("q", "");
	  data.append("cat", "");
    fetch("https://rest.smog.co.id/v2/smogger/master-cig-list",{method:'POST',headers: {
    Accept: 'application/json',
	'Content-Type': 'multipart/form-data',
},body: {
	"t":"fMqyXjyjrOSd3YRa1krLKNw8yZnTDxW4"
}})
    .then(response => response.json())
    .then((responseJson)=> {
      console.log(responseJson);
        this.setState({
          datas:responseJson.result
        });
      
    })
    .catch(error=>console.log(error)) //to catch the errors if any
}

	render() {
    const { ProductDatabase,visible,isOpen } = this.state;
    const items = ProductDatabase.filter((ProductDatabase)=>{
      if(this.state.search == null)
          return ProductDatabase
      else if(ProductDatabase.name.toLowerCase().includes(this.state.search.toLowerCase()) || ProductDatabase.name.toLowerCase().includes(this.state.search.toLowerCase())){
          return ProductDatabase
      }
      return null;
    }).map((item,index)=>(
      <div className="col-sm-3" key={index}>
    <div className="card wow fadeInUp mb-4" style={{height:270,backgroundColor:'white'}} onClick={() => this.openModalWithItem(index)}>
      <div style={{display:'block', position:'relative'}}>
        <figure>
          <img src={item.image} style={{height:80}}/>
        </figure>
      </div>
      <div className="card-content">
        <div className="container">
          <div style={{textAlign:'left'}}>
          <p style={{color:'grey'}}>{item.brand}</p>
            <h6 style={{color:'black'}} >{item.name}</h6>
            <p style={{color:'grey'}}>{item.code}</p>
          </div>
        </div>
      </div>
    </div>
    
  </div>
    ))
    const requiredItem = this.state.requiredItem;
    let modalData = ProductDatabase[requiredItem];
		return(
      <div>
      <Navbar scrolling dark expand="md" fixed="top"style={{backgroundColor:this.state.color}}>
          <NavbarToggler onClick={this.toggle} color='#fff'/>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="mr-auto" navbar style={{paddingRight:20}} pills defaultActiveKey="#" as="ul">
              <NavItem className="navItem" as="li" >
                <NavLink onClick={()=>{this.props.pindahHalaman('home')}} href="#Home">Home</NavLink>
              </NavItem>
              <NavItem className="navItem" as="li" active>
                <NavLink style={{color:'orange',fontWeight:'bold'}} onClick={()=>{this.props.pindahHalaman('product')}} href="#Product">Product</NavLink>
              </NavItem>
              <NavItem className="navItem" as="li">
              <NavLink onClick={()=>{this.props.pindahHalaman('career')}} href="#Career">Event</NavLink>
            </NavItem>
              <NavItem className="navItem" as="li" >
                <NavLink onClick={()=>{this.props.pindahHalaman('contact')}} href="#Contact">Contact Us</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      <section id="intro">
		  <div class="intro-container jumbo2">
			<div id="introCarousel" class="carousel  slide carousel-fade" data-ride="carousel">
	  
			  <div class="carousel-inner" role="listbox">
	  
				<div class="carousel-item active">
				  <div class="container mb-3 mainContent" style={{marginTop:100}}>
          <div className="section-header wow fadeInUp">
            <h3 style={{color:'white'}}>Our Product</h3>
            </div>
          <div data-aos="zoom-out" style={{flexDirection:'column',marginTop:50}}>
          <img src={BrandLogo1} style={{minHeight:50,maxHeight:200, marginRight:20}} />
          <img src={BrandLogo2} style={{minHeight:50,maxHeight:200,marginLeft:20}}/>
          </div>
          
				  </div>
				</div>
		  </div>
      </div>
      <Footer/>
      </div>
		</section>
    </div>
		)
	}
}

export default BrandContainer;