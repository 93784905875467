import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Event.css';
import Footer from '../components/Footer2';
import ModalComp from '../components/modalEvent'
import ModalImage from '../components/ModalImage'
import ModalVideo from '../components/ModalVideo'
import MainNav from '../components/MainNav';

const styles = theme => ({
	root: {
	  display: 'flex',
	  flexWrap: 'wrap',
	},
	formControl: {
	  minWidth: 200,
	},
	formControl2: {
	  minWidth: 120,
	},
	selectEmpty: {
	  marginTop: theme.spacing.unit * 2,
	},
  });

class StoryContainer extends Component {
	state = {
		color: '',id:'',imgUrl:'',vidUrl:'',
		isLoading: true,
		product:[],
		isDone: false,
		isVideo:false,
		modalItem: [],
		show: false,showImg:false,
		content:{}
	  }
	  listenScrollEvent = e => {
		if (window.scrollY > 400) {
		  this.setState({color: 'rgba(52, 52, 52, 0.8)'})
		} else {
		  this.setState({color: ''})
		}
	  }
	  closeModal=nr=>()=> {
		this.setState({
			show : false
		});
	  }
	  handleModal(id){
  
		this.setState({
		  show: true,
		  isDone:false
		 
		});
		this.loadModal(id);
	  }
	  closeModalImage=nr=>()=> {
		this.setState({
			showImg : false,
			show:true,
			isDone:false
		});
		this.loadModal(this.state.id);
	  }
	  handleModalImage(id){
  
		this.setState({
			showImg: true,
			show:false,
			imgUrl:id
		 
		});
	  }
	  closeModalVideo=nr=>()=> {
		this.setState({
			showVideo : false,
			show:true,
			isDone:false
		});
		this.loadModal(this.state.id);
	  }
	  handleModalVideo(id){
  
		this.setState({
			showVideo: true,
			show:false,
			vidUrl:id
		 
		});
	  }
	  loadModal(id){
		var myHeaders = new Headers();
	  myHeaders.append("Content-Type", "application/json");
	  
	  var raw = JSON.stringify({"t":"token","id":id});
	  
	  var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
	  };
	  
	  fetch(window.$storyFindById, requestOptions)
		.then(response => response.json())
		.then(result => {
		  //console.log(result)
		  this.setState({
			modalItem:result.result,
			id:result.result[0].id,
			isDone:true
		   
		  })
		  })
		.catch(error => console.log('error', error));
	  }
	
	
	  componentDidMount(){
		AOS.init({
			duration : 1500
		  })
		window.addEventListener('scroll', this.listenScrollEvent)
		this.loadEvent();
		this.loadContent();
	  }
	  loadContent(){
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
	
		var raw = "";
	
		var requestOptions = {
		  method: 'POST',
		  headers: myHeaders,
		  body: raw,
		  redirect: 'follow'
		};
	
		fetch(window.$contentIndexCompact, requestOptions)
		  .then(response => response.json())
		  .then(result => {
			this.setState({
			  content:result.result
			})
		  })
		  .catch(error => console.log('error', error));
	  }
	loadEvent(){
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({"t":"token"});

		var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
		};

		fetch(window.$storyIndex, requestOptions)
		.then(response => response.json())
		.then(result => {
			//console.log(result.result)
			this.setState({
				product:result.result,
				isLoading:false
			  });
		})
		.catch(error => console.log('error', error));

	}
	  loadImage(images){
		if(images[0]!=null){
			var urls=images[0].url.split('/')
			var img=window.$ImageBaseUrl+"/"+urls[0]+"/"+urls[1]+"/"+urls[2]+"/"+urls[3]+"/"+"L"+"/"+urls[4]
		  return img
		}
		return require("../assets/placeholder.jpg");
	  }
	  loadVideo(videos){
		if(videos[0]!=null){
			
		  return videos[0].url;
		}
		return ('');
	  }
	  checkVideo(videos){
		if(videos[0]!=null){
			
		  return true;
		}
		return false;
	  }
	  checkImage(image){
		if(image[0]!=null){
		  return true;
		}
		return false;
	  }
	  findByName(names){
		if(names!=null){
		  return names.value
		}
		 return ""
		
	   }
	   findByUrl(url){
		if(url!=null){
		  return window.$ImageBaseUrl+"/"+url.img_url
		}
		 return require('../assets/placeholder.jpg')
		
	   }
	render() {
		const { isLoading, catItem, product,searchItem,brandFilter,catFilter,brands,modalItem,isDone,isVideo,content } = this.state;
		const { classes } = this.props;
		return(
			<div>
			<Helmet>
			<title>PT Cipta Tapak Sejahtera - Story</title>
			<meta name="description" content="Story from PT Cipta Tapak Sejahtera" />
			</Helmet>
<Backdrop open={isLoading}>
<CircularProgress color="inherit" />
</Backdrop>
			<div>
			<MainNav route='story'/>
		
		<div className="mb-3 " style={{marginTop:100}}>
		
			<div className="container ">
				<div className="">
				<div>
					<div className="section-header wow fadeInUp">
					<h3>{this.findByName(content.STORIES_TITLE)}</h3>
					</div>
					<div className="row section-header wow fadeInUp form-group" style={{right:0}}>

					</div>

					<div className="container" style={{marginBottom:50}}>
						{isLoading ? (
						<div></div>
						) : ( <div className="product-grid row">
						{product.map((info)=>(
						<div className="col-sm-4 mb-3">
						<Paper elevation={3} >
						<div className="card wow fadeInUp mb-4" style={{height:270,backgroundColor:'white'}} >
							<div className="mt-2">
								{this.loadImage(info.images) ? (<img class="img-fluid "src={this.loadImage(info.images)} style={{height:80}} alt={info.name} title={info.name} onError={(e)=>e.target.src=window.$ImageBaseUrl+"/"+info.images[0].url}/>) : (<div><img class="img-fluid "src={"../assets/placeholder.jpg"} style={{height:80}}/></div>) }
							</div>
							<hr/>
							<div className="card-body" style={{textAlign:'left',flexGrow:1}}>
								<a href="#" class="text-primary" onClick={() => this.handleModal(info.id)}>
									{info.name}
								</a>
							</div>
						</div>

						</Paper>
						</div>
					))}
					</div>)
						}
						
					
					</div>

				</div>
				</div>
			</div>
			</div>
	
		  <Footer/>
		  { modalItem.map((info)=>(
			<ModalComp
			show={this.state.show}
			close={this.closeModal()}
			openImage={this}
			openVideo={this}
			name={info.name}
			images={info.images}
			videos={info.videos}
			videoSatus={this.checkVideo(info.videos)}
			imageSatus={this.checkImage(info.images)}
			image={this.loadImage(info.images)}
			video={this.loadVideo(info.videos)}
			isVideo={this.checkVideo(info.videos)}
			desc={info.description}
			done={isDone}
			/>
		  ))}
		  <ModalImage
		  show={this.state.showImg}
		  close={this.closeModalImage()}
		  image={this.state.imgUrl}	
		  />
		<ModalVideo
		show={this.state.showVideo}
		close={this.closeModalVideo()}
		video={this.state.vidUrl}
		/>
		  </div>
		  </div>
		)
	}
}

StoryContainer.propTypes = {
	classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(styles)(StoryContainer) ;