import React from "react";
import {
	Collapse,
	Navbar,
	NavbarToggler,
	//NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	//UncontrolledDropdown,
	//DropdownToggle,
	//DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  //Button, 
} from 'reactstrap';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import ContentLoader from "react-content-loader";
import ReactLoading from 'react-loading';
import Bars from './BarLoading';

class MyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }
  render(){
    return(
    <div>
    <Modal isOpen={this.props.show} onClose={this.props.close} backdrop="true" className="" size="large">
    {this.props.done ? ( 
        <div>
        <ModalHeader>{this.props.name}</ModalHeader>
      
        <ModalBody>
            <div className="container">
              <img className="img-fluid " src={this.props.image} alt={this.props.name} style={{maxHeight:300,maxWidth:400}}/>
              <div style={{textAlign:'left'}}>
              <p > 
                <text style={{color:'grey',textAlign:'left'}}>Brand : </text>
                <text style={{color:'black'}}>{this.props.brand}</text>
              </p>
              <p> 
                <text style={{color:'grey'}}>Category : </text>
                <text style={{color:'black'}}>{this.props.category}</text>
              </p>
              <p>
                <text style={{color:'grey',alignContent:'left'}}>Code : </text>
                <text style={{color:'black',alignContent:'left'}}>{this.props.code}</text>
              </p>
              <p>
                <text style={{color:'grey'}}>Description : </text>
                <div style={{height:200,overflowY:'scroll'}}>
                  <InputBase multiline fullWidth disabled style={{color:"#000"}} value={this.props.desc} />
                </div>
              </p>
              </div>
            </div>
        </ModalBody>
        </div>
      ) : (
       <ModalBody> <Bars/> </ModalBody>
      )}
      
      
      <ModalFooter>
        <Button onClick={this.props.close}>Close</Button>
      </ModalFooter>
              
            
    </Modal>
    </div>
  )
    }
}
export default MyModal