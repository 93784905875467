import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Helmet} from 'react-helmet';
import Footer from '../components/Footer2';
import CustomerDatabase from '../components/CostumerDatabase';
import MainNav from '../components/MainNav'

class HomeContainer extends Component {
	state = {
		isLoading: true,
        CustomerDatabase:CustomerDatabase,
		color: 'rgba(52, 52, 52, 0.8)',
		costumer:[],
		content:{}
	  }
	  listenScrollEvent = e => {
		if (window.scrollY > 400) {
		  this.setState({color: 'rgba(52, 52, 52, 0.8)'})
		} else {
		  this.setState({color: 'rgba(52, 52, 52, 0.8)'})
		}
	  }
	
	
	  componentDidMount(){
		window.addEventListener('scroll', this.listenScrollEvent)
		this.loadData()
		this.loadContent()
	  }
	  loadContent(){
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
	
		var raw = "";
	
		var requestOptions = {
		  method: 'POST',
		  headers: myHeaders,
		  body: raw,
		  redirect: 'follow'
		};
	
		fetch(window.$contentIndexCompact, requestOptions)
		  .then(response => response.json())
		  .then(result => {
			this.setState({
			  content:result.result
			})
		  })
		  .catch(error => console.log('error', error));
	  }
	  loadData(){
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		
		var raw = JSON.stringify({"t":"token"});
		
		var requestOptions = {
		  method: 'POST',
		  headers: myHeaders,
		  body: raw,
		  redirect: 'follow'
		};
		
		fetch(window.$customerIndex, requestOptions)
		  .then(response => response.json())
		  .then(responseJson => {
			this.setState({
				costumer:responseJson.result,
				isLoading:false
			})
		  })
		  .catch(error => console.log('error', error));
	  }
	  checkLogo(logo){
		  if(logo!=""){
			  return true;
		  }
		return false;
	  }
	  findByName(names){
		if(names!=null){
		  return names.value
		}
		 return ""
		
	   }
	   findByUrl(url){
		if(url!=null){
			var urls=url.split('/')
    		var img=window.$ImageBaseUrl+"/"+urls[0]+"/"+urls[1]+"/"+urls[2]+"/"+"M"+"/"+urls[3]
		  return img
		}
		 return require('../assets/placeholder.jpg')
		
	   }
	render() {
        const { CustomerDatabase,costumer,isLoading,content } = this.state;
		return(
			<div>
			<Helmet>
			<title>PT Cipta tapak sejahtera - Customer</title>
			<meta name="description" content="Customer using product from PT Cipta Tapak Sejahtera" />
			</Helmet>
			<Backdrop open={isLoading}>
			<CircularProgress color="inherit" />
			</Backdrop>
			<div>
			<MainNav route='customer'/>
			<div className="container" style={{marginTop:120}}>
	  
			  <div className="section-header wow fadeInUp">
				<h3 >{this.findByName(content.CUSTOMER_TITLE)}</h3>
			  </div>
			  <div className="container mt-3" style={{marginBottom:50}}>
			  {isLoading ? (
				<div></div>
			  ) : (
				<div className="row product-grid">
                {
                    costumer.map((item)=>(
						<div className="col-sm-3 mb-3">
						<Paper elevation={3}>
						<div className="d-flex justify-content-center"  style={{height:170,backgroundColor:'white'}} >
						{this.checkLogo(item.logo_url)? (<img title={item.name} style={{maxHeight:150, maxWidth:220,marginTop:10,objectFit:'contain'}} src={this.findByUrl(item.logo_url)} alt={item.name} onError={(e)=>{e.target.src=window.$ImageBaseUrl+"/"+item.logo_url}}/>):(<img style={{maxHeight:150, maxWidth:220}} src={require('../assets/placeholder.jpg')}/>)}
						</div>
						</Paper>
						</div>
                    ))
				}
				</div>
			  )}
              </div>
			  
			</div>
		  <Footer/>
		  </div>
		  </div>
		)
	}
}

export default HomeContainer;