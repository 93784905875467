import React, { Component } from 'react';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';
import Loader from './Loader'



class ProfileContainer extends Component {
	render() {
		return(
            <div>
            <div className="wow fadeInUp" style={{height:270}}>
            <div >
                    <div >
                    <div className="row">
                        <div className="col-sm-3" style={{marginBottom:5}}>
                        <div style={{height:270}}>
                        <Loader />
                        </div>
                        </div>
                        <div className="col-sm-3">
                        <div style={{height:270}}>
                        <Loader />
                        </div>
                        </div>
                        <div className="col-sm-3" style={{marginBottom:5}}>
                        <div style={{height:270}}>
                        <Loader />
                        </div>
                        </div>
                        <div className="col-sm-3">
                        <div style={{height:270}}>
                        <Loader />
                        </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3">
                        <div style={{height:270}}>
                        <Loader />
                        </div>
                        </div>
                        <div className="col-sm-3">
                        <div style={{height:270}}>
                        <Loader />
                        </div>
                        </div>
                        <div className="col-sm-3">
                        <div style={{height:270}}>
                        <Loader />
                        </div>
                        </div>
                        <div className="col-sm-3">
                        <div style={{height:270}}>
                        <Loader />
                        </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
		)
	}
}

export default ProfileContainer;