import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Bars from './BarLoading';
import Paper from '@material-ui/core/Paper';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
});


class MyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      showImg:false,
      value: 0,
    };
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };

  checkImage(image){
    if(image!==null){
      return true
    }
    return false
  }
  findByUrl(url){

    if(url!=null){
      var urls=url.split('/')
      var img=window.$ImageBaseUrl+"/"+urls[0]+"/"+urls[1]+"/"+urls[2]+"/"+"L"+"/"+urls[3]
      return img
    }
     return require('../assets/placeholder.jpg')
    
   }
  checkVideo(image){
    if(image!==null){
      return true
    }
    return false
  }
  
  render(){
    const { classes } = this.props;
    const{showImg,value}=this.state;
    return(
    <React.Fragment>
    <Dialog open={this.props.show} onClose={this.props.close} fullWidth maxWidth="md" scroll="body">
    {this.props.done ? ( 
        <div>
        <DialogTitle>{this.props.name}</DialogTitle>
        <Divider/>
        <DialogContent>
              <div>
              <p>
                <div style={{height:150,overflowY:'scroll', textAlign:'left'}}>
                  <InputBase multiline fullWidth disabled style={{color:"#000"}} value={this.props.desc} /> 
                </div>
              </p>
              </div>
              <div>
              <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                scrollButtons="auto"
              >
                <Tab label="Image" />
                <Tab label="Video" />
              </Tabs>
                </AppBar>
                {value === 0 && <TabContainer>
                {this.props.imageSatus?(
                  <p>
                  <div >
                  <div className="product-grid row">
                    {this.props.images.map((info)=>(
                    <div className="col-6 col-md-4">
                    <Paper style={{height:270,backgroundColor:'white'}}>
                      <div className="card wow fadeInUp mb-4"  style={{height:320,backgroundColor:'white'}}>
                        <div className="mt-2 d-flex justify-content-center mb-2 ml-2 mr-2" >
                        <a href="#" class="text-primary" onClick={e => this.props.openImage.handleModalImage(info.url)}><img class="img-fluid "src={ this.findByUrl(info.url)} style={{maxHeight:200}} alt="img" onError={(e)=>e.target.src=window.$ImageBaseUrl+"/"+info.url}/></a>
                        </div>
                        <Divider/>
                        <div className="card-body" style={{textAlign:'left',flexGrow:1}}>
                            <InputBase multiline fullWidth disabled style={{color:"#000"}} value={info.description} />
                              <br />
                              
                        </div>
                      </div>
    
                    </Paper>
                    </div>
                  ))}
                  </div>
                  </div>
                  </p>
                ):(
                  <p>
                  <h3>Image not avaliable</h3>
                  </p>)}
                  </TabContainer>}
                {value === 1 && <TabContainer>
                {this.props.videoSatus?(
                  <p>
                  <div >
                  <div className="product-grid row">
                    {this.props.videos.map((info)=>(
                    <div className="col-6 col-md-4">
                    <Paper style={{height:270,backgroundColor:'white'}}>
                      <div className="card wow fadeInUp mb-4"  style={{height:320,backgroundColor:'white'}}>
                        <div className="mt-2 d-flex justify-content-center mb-2" >
                        <a href="#" class="text-primary" onClick={e => this.props.openVideo.handleModalVideo(info.url)}><img class="img-fluid "src={require('../assets/play-logo.png')} style={{maxHeight:200}}/></a>
                        </div>
                        <Divider/>
                        <div className="card-body" style={{textAlign:'left',flexGrow:1}}>
                            <InputBase multiline fullWidth disabled style={{color:"#000"}} value={info.description} />
                              <br />
                              
                        </div>
                      </div>
    
                    </Paper>
                    </div>
                  ))}
                  </div>
                  </div>
                  </p>
                ):(
                  <p>
                  <h3>Video not avaliable</h3>
                  </p>)}
                  
                  </TabContainer>}
              </div>
        </DialogContent>
        </div>
      ) : (
       <DialogContent> <Bars/> </DialogContent>
      )}
      
      <Divider/>
      <DialogActions>
        <Button onClick={this.props.close}>Close</Button>
      </DialogActions>
              
            
    </Dialog>
    </React.Fragment>
  )
    }
}

MyModal.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(MyModal);