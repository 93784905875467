import React, { Component } from 'react';
import InputBase from '@material-ui/core/InputBase';
import PicMap  from "../components/PicMap"
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Home.css';
const companyLogo = require('../assets/company-logo2.png');
const companyName = require('../assets/company-name.png');
class HomeContainer extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      image_height:null,
      data:{},
      items1:{},
      items2:{},
      items3:{},
      items4:{},
      items5:{},
      pinss:[],img_url:''
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  state = {
    color: ''
  }
  listenScrollEvent = e => {
    if (window.scrollY > 400) {
      this.setState({color: 'rgba(52, 52, 52, 0.8)'})
    } else {
      this.setState({color: ''})
    }
  }


  componentDidMount(){
    this.setImageSize()
    this.laodData()
    this.loadPins('MY_BRAND_MAP')
    AOS.init({
      duration : 1500
    })
    window.addEventListener('scroll', this.listenScrollEvent)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({"t":"token"});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(window.$productIndexImage, requestOptions)
  .then(response => response.json())
  .then(result => {
    //console.log(result.result[0])
    this.setState({
      items1:result.result[0],
      items2:result.result[1],
      items3:result.result[2],
      items4:result.result[3],
      items5:result.result[4],
    })

  })
  .catch(error => console.log('error', error));


  }

    loadPins(name){
      var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
      
          var raw = JSON.stringify({"t":"token","name":name});
      
          var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
          };
      
      fetch(window.$picmapFindByName, requestOptions)
          .then(response => response.json())
          .then(result => {
              const newData=result.result[0].items.map(a=>({...a}))
              this.setState({
                  img_url:result.result[0].img_url,
                  pinss:newData
              })
              
          })
          .catch(error => console.log('error', error));
    }
  laodData(){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = "";

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(window.$contentIndexCompact, requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({
          data:result.result
        })
      })
      .catch(error => console.log('error', error));
  }

  setImageSize = () => {
    if(window.innerWidth < 600) {
        this.setState({ image_height: 10})
      } else {
        this.setState({image_height: 50})
      }  
    }
    
  findByName(names){
    if(names!=null){
      return names.value
    }
     return ""
    
   }
   findByUrl(url){

    if(url!=null){
      var urls=url.img_url.split('/')
      var img=window.$ImageBaseUrl+"/"+urls[0]+"/"+urls[1]+"/"+urls[2]+"/"+"L"+"/"+urls[3]
      return img
    }
     return require('../assets/placeholder.jpg')
    
   }
   loadImageItem(url){

    if(url!=null){
      var urls=url.split('/')
      var img=window.$ImageBaseUrl+"/"+urls[0]+"/"+urls[1]+"/"+urls[2]+"/"+"L"+"/"+urls[3]
      return img
    }
     return require('../assets/placeholder.jpg')
    
   }
	render() {
    const { items1,items2,items3,items4,items5,data } = this.state;

		return(
      <div>
		  <section id="intro">
		  <div className="intro-container jumbo2">
			<div id="introCarousel" className="carousel  slide carousel-fade" data-ride="carousel">
	  
			  <div className="carousel-inner" role="listbox">
	  
				<div className="carousel-item active">
				  <div className="carousel-container">
          <div className="carousel-content">
          <h3 style={{color:'white',fontWeight:'bold',fontSize:36}}>Welcome</h3>
          <h3 style={{color:'white',fontWeight:'bold'}}>to</h3>
          <div data-aos="zoom-out" style={{flexDirection:'column'}}>
          <img className="img-fluid" src={companyLogo} />
          <img className="img-fluid"src={companyName} />
          </div>
					</div>
				  </div>
				</div>
		  </div>
		  </div>
		  </div>
		</section>

      <div className="container pt-4">

        <header className="section-header "data-aos="fade-up">
          <h3>{this.findByName(data.ABOUT_US_TITLE)}</h3>
          <p>{this.findByName(data.ABOUT_US_CONTENT_P1)}</p>
          <p>{this.findByName(data.ABOUT_US_CONTENT_P2)}</p>
        </header>
	  </div>
	  <div className="jumbo">
            <div className="container">
            <div className="vismis-cols pb-4 pt-4" style={{flexDirection:'row'}}>
                <div className="col "data-aos="fade-up-right">
                <h2  style={{textAlign:'left',verticalAlign:'middle',color:'black',fontWeight:'bold'}}>{this.findByName(data.OUR_VISION_TITLE)}</h2>
                <div className="textItem" style={{textAlign:'left',verticalAlign:'middle',marginTop:20}}>
                <p style={{fontSize:20, color:'black'}}>{this.findByName(data.OUR_VISION_CONTENT)}</p>
                </div>
                </div>
                <div className="d-flex justify-content-end">
                <div className="row d-flex justify-content-end containerItem" data-aos="fade-up-left" data-aos-delay="50" style={{textAlign:'right'}}>
                <h2  style={{textAlign:'right',color:'black',fontWeight:'bold',right:0}}>{this.findByName(data.OUR_MISSION_TITLE)}</h2>
                <p style={{fontSize:20, color:'black'}}>{this.findByName(data.OUR_MISSION_CONTENT)}</p>
                </div>
                </div>
                <div className="col "data-aos="fade-up-right" data-aos-delay="100">
                <h2  style={{textAlign:'left',verticalAlign:'middle',color:'black',fontWeight:'bold'}}>{this.findByName(data.OUR_STRATEGY_TITLE)}</h2>
                <div style={{textAlign:'left',verticalAlign:'middle',marginTop:20}}>
                
                <InputBase multiline fullWidth disabled style={{fontSize:20, color:'black'}} value={this.findByName(data.OUR_STRATEGY_CONTENT)}/>
                
                </div>
                
                </div>
                </div>
            </div>
            </div>
            <div>
            <div className="container">
            <div className="pb-4 pt-4" style={{flexDirection:'row'}}>
            <div className="section-header wow fadeInUp">
            <h3>{this.findByName(data.SALES_ON_MAP_TITLE)}</h3>
            </div>
            <div className="justify-content-center d-flex mb-4">
            <div className="wow fadeInUp" style={{overflowX:'scroll'}}>
            <PicMap imgmain={window.$ImageBaseUrl+"/"+this.state.img_url} pins={this.state.pinss}/>
            </div>
            </div>
                </div>
            </div>
            </div>
  <footer className="page-footer " style={{backgroundColor:'#17181c'}}>
  <div className="row">
		 <div className="col sm-4"></div>
		 <div className="col sm-4">
		 <div className="footer-copyright text-center py-4 mt-2" style={{color:'white'}}>
          © {this.findByName(data.COMPANY_NAME)}
          </div>
		 </div>
     <div className="col sm-4 justify-content-end d-flex" >
		 <div className="row">
		 <div className="col"></div>
		 <div className="col">
		 <div className="row">
		 <div className="col sm-4  d-flex"> 
		 <div className="mr-1 mt-1 jsutify-content">
     <text style={{fontSize:14,color:'#fff'}}>Member of:</text>
		 </div>
		 </div>
		 <div className="w-100"></div>
		 <div className="col col sm-4 justify-content-end d-flex">
		 <div className="mr-3 mt-2 mb-2">
		 <a href="https://interciptacorp.com/" target="_blank"><img src={require('../assets/logo_intercipta.png')} style={{width:180,height:50}}></img></a>
		 </div>
		 </div>
		 </div>
		 </div>
		 </div>
		 </div> 
		  </div>
  </footer>
	</div>
		)
	}
}

export default HomeContainer;